<template>
    <div id="detail-tank-page">
        <toolbar-back-button-component :title="tank.name" :subtitle="getFormattedAddress(tank.domicile)"
                                       icon_subtitle="mdi-map-marker-outline"
        />

        <div class="pa-2">
            <v-row class="ma-0">
                <v-col class="pb-0" cols="12" lg="6" data-cy="col-detail-tank">
                    <v-card class="elevation-0">
                        <v-card-subtitle>
                            <p class="mb-0">
                                {{ $t('detail_tank_page.device_number') }} :
                                <span v-for="(device, index) in tank.devices" :key="device.id">
                                    {{ device.device_number }} {{ tank.devices.length !== index + 1 ? ' - ' : '' }}
                                </span>
                            </p>

                            <chips-last-statement :danger="345600" :date="tank.date_last_seen" :warning="172800"
                                                  previous_text="Dernière émission"
                            />
                        </v-card-subtitle>

                        <v-card-text class="pb-8">
                            <display-liquid-level-component
                                :actual_volume="tank.actual_volume"
                                :id_asset="tank.id_asset"
                                :show_indicator="true"
                                :to="{ path: '/tank/' + tank.id}"
                                :volume_total="tank.volume_total"
                                :type_asset="tank.type"
                                class="mb-3"
                            />
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="6" data-cy="col-widgets-detail-tank">
                    <drop-list :items="order_configuration_equipment"
                               @insert="insert"
                               @reorder="$event.apply(order_configuration_equipment)"
                    >
                        <template v-slot:item="{item}">

                            <drag :key="item.widget_name" :data="item"
                                  @cut="remove(order_configuration_equipment, item)"
                                  @dragend="updateOrCreatePageOrderEquipmentConfiguration()"
                            >
                                <v-row
                                    v-if="item.is_display && !(item.widget_name === 'fullness-history-component' && !tank.operations)" data-cy="detail-asset-widget-wrapper">
                                    <v-col class="d-flex" cols="1 pr-0">
                                        <v-icon class="pl-4 background-white width-inherit z-index-1 rounded"
                                                color="black"
                                        >
                                            mdi-equal
                                        </v-icon>
                                    </v-col>
                                    <v-col class="dnd-no-drag pl-0" cols="11">
                                        <template v-if="item.widget_name === 'information-asset-component'">
                                            <information-asset-component :asset="tank" :is_open="item.is_open"/>
                                        </template>

                                        <template v-if="item.widget_name === 'info-level-component'">
                                            <info-level-component :asset="tank" :is_open="item.is_open"/>
                                        </template>

                                        <template v-if="item.widget_name === 'estimated-days-exhaustion-component'">
                                            <estimated-days-exhaustion-component :asset="tank" :is_open="item.is_open"/>
                                        </template>

                                        <template v-if="item.widget_name === 'information-device-component'">
                                            <information-device-component v-for="device in tank.devices"
                                                                          :is_open="item.is_open"
                                                                          :device="device"
                                                                          :key="'information_device_' + device.id_device"
                                            />
                                        </template>

                                        <template v-if="item.widget_name === 'fullness-history-component'">
                                            <fullness-history-component :is_open="item.is_open"
                                                                        :operations="tank.operations"
                                            />
                                        </template>

                                        <template v-if="item.widget_name === 'consumption-report-component'">
                                            <!-- TODO: Just for apple store -> condition to remove -->
                                            <template v-if="tank.is_subscription_device_active">
                                                <consumption-report-component v-for="device in tank.devices"
                                                                              :key="'consumption_' + device.id_device"
                                                                              :id_asset="tank.id_asset"
                                                                              :id_device="device.id_device"
                                                                              :is_subscription_active="device.is_subscription_active"
                                                                              :is_open="item.is_open"
                                                />
                                            </template>
                                        </template>

                                        <template v-if="item.widget_name === 'chart-level-component'">
                                            <!-- TODO: Just for apple store -> condition to remove -->
                                            <template
                                                v-if="tank.is_subscription_device_active">
                                                <chart-level-component v-for="device in tank.devices"
                                                                       :key="'chart_' + device.id_device"
                                                                       :is_open="item.is_open"
                                                                       :device="device"
                                                />
                                            </template>
                                        </template>

                                        <template v-if="item.widget_name === 'image-equipment-component'">
                                            <image-equipment-component :asset="tank" :is_open="item.is_open"
                                                                       v-on:refreshTank="refreshTank"
                                            />
                                        </template>

                                        <template v-if="item.widget_name === 'economy-co2-component'">
                                            <economy-co2-component :is_open="item.is_open"
                                                                   :co2_economy_in_year="tank.co2_economy_in_year"
                                            />
                                        </template>

                                        <template v-if="item.widget_name === 'alerts-configured-component'">
                                            <alerts-configured-component :is_open="item.is_open" :tank="tank"/>
                                        </template>
                                    </v-col>
                                </v-row>
                            </drag>
                        </template>
                    </drop-list>
                </v-col>
            </v-row>
            <div class="bottom-hidden-div"/>

            <v-speed-dial
                v-model="settings"
                class="bottom-left-btn-desktop"
                direction="top"
                transition="slide-y-reverse-transition"
                v-if="userHasAccess('TNK', this.role_vision) || userHasAccess('BASE', this.role_vision)"
                data-cy="btn-option-detail-asset"
            >
                <template v-slot:activator>
                    <v-btn v-model="settings" color="secondary" elevation="0" fab>
                        <v-icon v-if="settings" color="primary">
                            mdi-close
                        </v-icon>

                        <v-icon v-else color="primary">
                            mdi-cog
                        </v-icon>
                    </v-btn>
                </template>
                <!--
                <v-btn color="primary" elevation="0" fab>
                    <v-icon color="secondary">mdi-access-point-network</v-icon>
                </v-btn>

                <v-btn color="primary" elevation="0" fab >
                    <v-icon color="secondary">mdi-chart-box-plus-outline</v-icon>
                </v-btn>
                -->
                <v-btn :to="{ path: '/equipment/manage_alert/' + tank.id_asset}" color="primary" elevation="0" fab
                       v-if="userHasAccess('BASE', this.role_vision)"
                >
                    <v-icon color="secondary">mdi-bell-ring</v-icon>
                </v-btn>

                <v-btn color="primary" elevation="0" fab v-if="userHasAccess('TNK', this.role_vision)"
                       @click="$refs.DialogManageWidgetComponent.dialog_manage_widgets = true"
                       data-cy="manage-widgets-btn"
                >
                    <v-icon color="secondary">mdi-chart-box-plus-outline</v-icon>
                </v-btn>

                <v-btn color="primary" elevation="0" fab v-if="userHasAccess('TNK', this.role_admin)"
                       @click="$refs.manageEquipmentComponent.dialog_manage_equipment = true"
                >
                    <v-icon color="secondary">mdi-pencil</v-icon>
                </v-btn>
            </v-speed-dial>
        </div>

        <manage-equipment-component ref="manageEquipmentComponent" :asset="tank" v-on:refreshTank="refreshTank" v-if="userHasAccess('TNK', role_admin)" data-cy="manage-equipment-component"/>

        <dialog-manage-widget-component ref="DialogManageWidgetComponent" v-if="userHasAccess('TNK', role_vision)" data-cy="dialog-manage-widget-component"/>
    </div>
</template>

<script>
import InformationAssetComponent from "@/components/Widget/InformationAssetComponent";
import InfoLevelComponent from "@/components/Widget/InfoLevelComponent";
import InformationDeviceComponent from "@/components/Widget/InformationDeviceComponent";
import FullnessHistoryComponent from "@/components/Widget/FullnessHistoryComponent";
import EstimatedDaysExhaustionComponent from "@/components/Widget/EstimatedDaysExhaustionComponent";
import ChartLevelComponent from "@/components/Widget/ChartLevelComponent";
import ConsumptionReportComponent from "@/components/Widget/ConsumptionReportComponent";
import ImageEquipmentComponent from "@/components/Widget/ImageEquipmentComponent";
import ChipsLastStatement from "@/components/Global/ChipsLastStatement";
import {Drag, DropList} from 'vue-easy-dnd';
import ToolbarBackButtonComponent from "@/components/Global/ToolbarBackButtonComponent";
import ManageEquipmentComponent from "@/components/EquipmentManagement/ManageEquipmentComponent";
import ClientRepository from "../../repositories/ClientRepository";
import DisplayLiquidLevelComponent from "../../components/Equipment/LiquidLevelComponent/DisplayLiquidLevelComponent";
import AlertsConfiguredComponent from "../../components/Widget/AlertsConfiguredComponent";
import EconomyCo2Component from "../../components/Widget/EconomyCo2Component";
import DialogManageWidgetComponent from "../../components/Widget/DialogManageWidgetComponent";

export default {
    name: "DetailTankPage",
    components: {
        ToolbarBackButtonComponent,
        ChipsLastStatement,
        ImageEquipmentComponent,
        InformationAssetComponent,
        InfoLevelComponent,
        FullnessHistoryComponent,
        EstimatedDaysExhaustionComponent,
        ChartLevelComponent,
        ConsumptionReportComponent,
        Drag,
        DropList,
        ManageEquipmentComponent,
        DisplayLiquidLevelComponent,
        AlertsConfiguredComponent,
        EconomyCo2Component,
        DialogManageWidgetComponent,
        InformationDeviceComponent
    },
    created() {
        //Scroll to the top of the page
        window.scrollTo(0, 0);
    },
    data() {
        return {
            page_name: 'equipment',
            research: '',
            tank: this.$store.getters['tanks/getTankById'](this.$route.params.id),
            settings: false
        }
    },
    computed: {
        order_configuration_equipment: {
            get: function () {
                return this.$store.getters['page_order_configuration/order_configuration_equipment'];
            },
            set: function (value) {
                this.$store.dispatch('page_order_configuration/getPageOrderConfigurationEquipment');
            }
        }
    },
    methods: {
        insert(event) {
            this.items.splice(event.index, 0, event.data);
        },

        remove(array, value) {
            let index = array.indexOf(value);
            array.splice(index, 1);
        },

        updateOrCreatePageOrderEquipmentConfiguration() {
            if (this.$store.getters['page_order_configuration/page_order_configuration_equipment'].id_page_order) {
                this.$store.dispatch('page_order_configuration/updatePageOrderConfigurationEquipment', {
                    page: 'equipment',
                    order_configuration: this.order_configuration_equipment
                }).catch((error) => {
                    this.manageError(error);
                });
            } else {
                this.$store.dispatch('page_order_configuration/createPageOrderConfigurationEquipment', {
                    page: 'equipment',
                    order_configuration: this.order_configuration_equipment
                }).catch((error) => {
                    this.manageError(error);
                });
            }
        },

        refreshTank() {
            ClientRepository.getUserAssetsInformationByAssetTypeAndIdClient(
                this.$store.getters['user_connected/user_connected'].id_client, this.$store.getters['asset_types/asset_types'].find(asset_type => asset_type.key === 'cuve').id_referential
            ).then((success) => {
                this.$store.commit('tanks/setTanks', success.data.data);
                this.tank = this.$store.getters['tanks/getTankById'](this.$route.params.id);
            }).catch((error) => {
                this.manageError(error);
            })
        }
    },
    mounted() {
        if (!this.tank.is_subscription_device_active) {
            this.showSnackbar('error', this.$t('detail_tank_page.subscriptions_terminated'));
            this.navigateTo('equipment');
        }
    }
}
</script>