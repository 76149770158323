<template>
    <div id="consumption-report-component" v-if="is_subscription_active">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component :is_open="is_open" widget_name="consumption-report-component"
                                         :title="$t('consumption_report_component.report_consumption')"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text class="pb-4">
                        <v-row class="ma-0 mb-4">
                            <span class="mr-4 font-weight-bold">
                                {{ $t('consumption_report_component.average') }}
                            </span>
                            <v-switch v-model="switch_accumulate_consumption" inset class="ma-0 pa-0"
                                      @click="getInformationConsumption()" hide-details
                            />
                            <span class="mr-4 font-weight-bold">
                                {{ $t('consumption_report_component.accumulation') }}
                            </span>
                        </v-row>

                        <date-range-picker-component :date_range="date_range" :loading="loading"
                                                     v-on:update="updateRangeDate" class="mb-4"
                        />

                        <v-row class="ma-0">
                            <v-col class="pt-0">
                                <span class="font-36 font-weight-bold mr-2">
                                    {{ numberWithSpaces(this.consumption) }}
                                    {{ switch_accumulate_consumption ? 'L' : 'L/j' }}
                                </span>
                                <span class="font-16">{{ $t('consumption_report_component.consumed') }}</span>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="d-flex pt-0">
                                <span class="font-20 font-weight-bold mr-2">{{ this.total_drain_consumption }}</span>
                                {{ $t('consumption_report_component.filling') }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import DeviceRepository from "@/repositories/DeviceRepository";
import DateRangePickerComponent from "../Global/DateRangePickerComponent";
import HeaderWidgetComponent from "./HeaderWidgetComponent";

export default {
    name: "ConsumptionReportComponent",
    components: {DateRangePickerComponent, HeaderWidgetComponent},
    data() {
        return {
            loading: false,
            date_range: {
                startDate: this.moment().clone().startOf('day').subtract(30, 'days').toDate(),
                endDate: this.moment().toDate()
            },

            switch_accumulate_consumption: false,
            menu_date_picker: false,

            consumption: 0,
            total_drain_consumption: 0
        }
    },
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false
        },
        id_device: {
            type: Number,
            required: true
        },
        id_asset: {
            type: Number,
            required: true
        },
        is_subscription_active: {
            type: Boolean,
            required: false
        }
    },
    created() {
        if (this.is_subscription_active) {
            this.getInformationConsumption();
        }
    },
    methods: {
        getInformationConsumption() {
            this.loading = true;
            DeviceRepository.getDeviceDataConsumptionByIdDevice(this.id_device, {
                'id_asset': this.id_asset,
                'date_begin': this.date_range.startDate,
                'date_end': this.date_range.endDate,
                'is_average': !this.switch_accumulate_consumption
            }).then((success) => {
                this.consumption = success.data.data.consumption;
                this.total_drain_consumption = success.data.data.total_drain_consumption;
            }).catch((error) => {
                this.manageError(error)
            }).finally(() => {
                this.loading = false;
            });
        },

        updateRangeDate(date_range) {
            this.date_range = date_range;
            this.getInformationConsumption();
        }
    }
}
</script>
