<template>
    <div id="alerts-configured-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component :is_open="is_open" widget_name="alerts-configured-component"
                                         :title="$t('alerts_configured_component.alerts_parameter')"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">

                    <v-card-text class="pt-0 pb-4">
                        <div v-if="!loading_alerts">
                            <v-row v-for="alert in alerts.filter(x => x.triggered)" :key="alert.id_alert"
                                   class="ma-0 mb-2"
                            >

                                <div class="tooltip-tab-metric position-relative">
                                    <v-btn :color="alert.configuration_appearance.color_triggered"
                                           class="bounce-btn" elevation="0" fab small
                                    >
                                        <v-icon class="color-white-fix">
                                            mdi-{{ alert.configuration_appearance.icon_triggered }}
                                        </v-icon>
                                    </v-btn>

                                    <div class='tooltip-tab-metric-content text-center'>
                                         <span class="secondary--text font-weight-bold">
                                             {{ alert.label }}
                                         </span>
                                        <v-btn class="mt-1" color="primary" small block>
                                            <span class="secondary--text font-weight-bold">
                                                {{ $t('global.access') }}
                                            </span>
                                        </v-btn>
                                    </div>
                                </div>


                                <div class="ml-2">
                                    <p class="font-color-medium font-weight-bold font-12 mb-n2 mt-1">
                                        {{ $t('alerts_configured_component.alert') }}
                                    </p>
                                    <p class="font-14 mb-0">{{ alert.label }}</p>
                                </div>
                            </v-row>

                            <v-divider class="my-2" v-if="alerts.filter(x => x.triggered).length > 0"/>

                            <v-row v-for="alert in alerts.filter(x => !x.triggered)" :key="alert.id_alert"
                                   class="ma-0 mb-2">
                                <v-btn :color="alert.configuration_appearance.color_untriggered"
                                       class="" elevation="0" fab small @click="openDialogUpdateAlert(alert.id_alert)"
                                >
                                    <v-icon class="color-white-fix">
                                        mdi-{{ alert.configuration_appearance.icon_untriggered }}
                                    </v-icon>
                                </v-btn>

                                <div class="ml-2">
                                    <p class="font-color-medium font-weight-bold font-12 mb-n2 mt-1">
                                        {{ $t('alerts_configured_component.alert') }}
                                    </p>
                                    <p class="font-14 mb-0">{{ alert.label }}</p>
                                </div>
                            </v-row>
                        </div>

                        <v-skeleton-loader v-else v-for="n in 2" :key="n" type="list-item-avatar" loading/>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>

        <dialog-update-alert-component
            ref="DialogUpdateAlertComponent"
            :alert="selected_alert"
            :is_new_alert="false"
            v-if="userHasAccess('BASE', this.role_edit)"
        />
    </div>
</template>

<script>
import DialogUpdateAlertComponent from "../Alert/DialogUpdateAlertComponent";
import HeaderWidgetComponent from "./HeaderWidgetComponent";
import DeviceRepository from "@/repositories/DeviceRepository";

export default {
    name: 'AlertsConfiguredComponent',
    components: {DialogUpdateAlertComponent, HeaderWidgetComponent},
    created() {
        this.getAlerts();
    },
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false
        },
        tank: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selected_alert: {
                user_preference: null,
                configuration: [],
                configuration_appearance: {}
            },
            alerts: [],
            loading_alerts: false
        }
    },
    methods: {
        getAlerts() {
            this.loading_alerts = true;
            DeviceRepository.getAllAlertByIdDevice(this.$store.getters['tanks/getTankById'](this.$route.params.id).devices[0].id_device).then((success) => {
                this.alerts = success.data.data;
                this.$refs.DialogUpdateAlertComponent.getMetrics(this.tank.devices[0].id_device);
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_alerts = false;
            });
        },

        openDialogUpdateAlert(id_alert) {
            this.selected_alert = this.alerts.find(x => x.id_alert === id_alert);
            this.$refs.DialogUpdateAlertComponent.dialog_edit_alert = true;
        }
    }
}
</script>

<style>
.tooltip-tab-metric-content {
    visibility: hidden;
    position: absolute;
    background: var(--v-white-base);
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border-radius: 5px;
    width: 200px;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
    padding: 10px;
    color: white;
    font-weight: normal;
    z-index: 99;
}

.tooltip-tab-metric:hover .tooltip-tab-metric-content {
    visibility: visible;
}

.tooltip-tab-metric .tooltip-tab-metric-content::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--v-white-base) transparent transparent transparent;
}
</style>