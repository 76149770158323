<template>
    <div id="fullness-history-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component :is_open="is_open" widget_name="fullness-history-component"
                    :title="$t('fullness_history_component.fullness_history')"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text class="pa-0 pb-4">
                        <v-simple-table class="table-history" v-if="operations.length > 0">
                            <thead>
                            <tr>
                                <th id="th-full-date" class="font-color-light">
                                    {{ $t('fullness_history_component.full_date') }}
                                </th>
                                <th id="th-added-volume" class="font-color-light">
                                    {{ $t('fullness_history_component.volume_added') }}
                                </th>
                                <th id="th-before-full" class="font-color-light">
                                    {{ $t('fullness_history_component.before_filling') }}
                                </th>
                                <th id="th-after-full" class="font-color-light">
                                    {{ $t('fullness_history_component.after_filling') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="operation in operations" :key="operation.id_operation">
                                <td class="white-space-nowrap">{{ moment(operation.date_operation).format(date_format) }}</td>
                                <td class="white-space-nowrap">{{ numberWithSpaces(operation.delta_volume) }}L</td>
                                <td class="white-space-nowrap">{{ numberWithSpaces(operation.measure_before_operation) }}L</td>
                                <td class="white-space-nowrap">{{ numberWithSpaces(operation.measure_after_operation) }}L</td>
                            </tr>
                            </tbody>
                        </v-simple-table>

                        <template v-else>
                            <h3 class="pl-4 pb-2">
                                {{ $t('fullness_history_component.no_history') }}
                            </h3>
                        </template>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import HeaderWidgetComponent from "./HeaderWidgetComponent";

export default {
    name: "FullnessHistoryComponent",
    components: {HeaderWidgetComponent},
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false
        },
        operations: {
            type: Array,
            required: true,
            default: () => []
        }
    }
}
</script>

<style scoped>
.table-history th {
    border-bottom: none !important;
}

.table-history td {
    border-bottom: none !important;
    height: 30px !important;
}
</style>