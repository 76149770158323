<template>
    <div id="economy-co2-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component
                    :is_open="is_open"
                    widget_name="economy-co2-component"
                    title="Votre économie en CO2 en 2022"
                />
            </v-card-title>

            <v-expand-transition>
                <v-card-text v-show="is_open">
                    <div class="text-center co2-container mb-12">
                        <div class="co2-principal-circle">
                            <div class="text-center position-relative co2-principal-circle-content">
                                <span :class="(co2_economy_in_year.toFixed(2).toString().length > 7 ? 'font-26' : 'font-36') + ' secondary--text font-weight-bold mb-0 line-height-24'">
                                    {{ co2_economy_in_year.toFixed(2) }}*
                                </span><br>
                                <span class="secondary--text font-14 tab-opacity">TCo2eq</span>
                            </div>
                        </div>
                        <v-icon @click="dialog_bilan_carbon = true" class="secondary--text co2-icon" data-cy="open-dialog-btn">
                            mdi-alert-circle-outline
                        </v-icon>

                        <div class="co2-secondary-circle">
                            <v-img src="/images/widget/economy-co2.png"
                                   height="115" width="115" class="co2-secondary-circle-img"
                            />
                        </div>
                    </div>
                </v-card-text>
            </v-expand-transition>
        </v-card>

        <v-dialog v-model="dialog_bilan_carbon" max-width="500px">
            <v-card data-cy="economy-co2-component-dialog">
                <div class="pa-3 text-center">
                    <span class="font-20 font-weight-bold text-uppercase">Base carbone</span>
                </div>
                <v-card-text class="pb-0">
                    <p class="line-height-18 mb-8">
                        Selon la <strong>Base Carbone</strong> de l'ADEME faisant référence :
                    </p>

                    <v-row>
                        <v-col align="center" justify="center" cols="5">
                            <p>Facteur d'émission d'Oleo100*</p>
                            <p>
                                <strong>1,23kgCO2eq/litre</strong>
                            </p>
                        </v-col>

                        <v-col align="center" class="pb-7 pt-4">
                            <v-divider vertical/>
                        </v-col>

                        <v-col align="center" justify="center" cols="5">
                            <p>Facteur d'émission du Gazole B7</p>
                            <p>
                                <strong>3,10kgCO2eq/litre</strong>
                            </p>
                        </v-col>
                    </v-row>

                    <p class="mt-4">
                        Oleo100 permet ainsi une réduction de <strong>minimum 60% des émissions de CO2 </strong> par
                        rapport au gazole B7.
                    </p>

                    <p class="font-color-light font-12 mb-2">*Biodisel - Filière Colza - sans changement d'affectation
                        des sols</p>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_bilan_carbon = false" data-cy="cancel-btn">
                        <span class="secondary--text font-weight-bold">{{ $t('global.close') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import HeaderWidgetComponent from "./HeaderWidgetComponent";

export default {
    name: 'EconomyCo2Component',
    components: {HeaderWidgetComponent},
    props: {
        co2_economy_in_year: {
          type: Number,
          required: true
        },
        is_open: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            dialog_bilan_carbon: false
        }
    }
}
</script>

<style>
.co2-container {
    height: 170px;
    width: 170px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}

.co2-principal-circle {
    background: var(--v-primary-base);
    border-radius: 100%;
    width: 170px;
    height: 170px;
}

.co2-principal-circle-content {
    top: 55%;
    transform: translateY(-50%);
}

.co2-icon {
    position: absolute !important;
    top: 55px;
    right: -35px;
    transform: scale(1.3);
}

.co2-secondary-circle {
    background: var(--v-primary-darken1);
    border-radius: 100%;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 90px;
    right: -70px;
}

.co2-secondary-circle-img {
    border-radius: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
