<template>
    <div id="estimated-days-exhaustion-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component :is_open="is_open" widget_name="estimated-days-exhaustion-component"
                                         :title="$t('estimated_days_exhaustion_component.title')"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text class="pt-2 pb-4">
                        <v-row class="text-center mb-1">
                            <v-col class="display-flex-content-center font-14 font-weight-bold pa-0">
                                <span class="font-18"
                                      v-text="asset.days_before_exhaustion + ' ' + (asset.days_before_exhaustion <= 1 ? $t('global.day') : $t('global.days'))"
                                >
                                </span>
                            </v-col>
                            <v-divider vertical color="white"/>
                            <v-col class="pa-0">
                                <span class="font-color-light font-12">
                                    {{ $t('estimated_days_exhaustion_component.exhaustion_estimated') }}
                                </span>
                                <p class="mb-0 font-weight-bold font-18">{{ date_before_exhaustion }}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import HeaderWidgetComponent from "./HeaderWidgetComponent";

export default {
    name: "EstimatedDaysExhaustionComponent",
    components: {HeaderWidgetComponent},
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false
        },
        asset: {
            type: Object,
            required: true,
            default: function () {
                return {days_before_exhaustion: null};
            }
        }
    },
    computed: {
        date_before_exhaustion() {
            return this.moment(new Date()).add(this.asset.date_before_exhaustion).format(this.date_format);
        }
    }
}
</script>
