<template>
    <div id="info-level-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component :is_open="is_open" widget_name="info-level-component"
                                         :title="$t('level_component.title')"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text class="pt-2 pb-4 text-center">
                        <v-row class="pb-4">
                            <v-col class="pb-0 pt-0">
                                <span class="font-12 font-color-light">{{ $t('level_component.volume_liter') }}</span>
                                <p class="asset-information">
                                    {{ numberWithSpaces(Math.round(asset.actual_volume)) }}L
                                </p>
                            </v-col>
                            <v-divider vertical color="white"/>
                            <v-col class="pb-0 pt-0">
                                <span class="font-12 font-color-light">{{ $t('level_component.hollow_liter') }}</span>
                                <p class="asset-information">{{ numberWithSpaces(Math.round(asset.hollow)) }}L</p>
                            </v-col>
                            <v-divider vertical color="white"/>
                            <v-col class="pb-0 pt-0">
                                <span class="font-12 font-color-light">
                                    {{ $t('level_component.level_percentage') }}
                                </span>
                                <p class="asset-information">{{ Math.round(asset.remaining_level) }}%</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import HeaderWidgetComponent from "./HeaderWidgetComponent";

export default {
    name: "InfoLevelComponent",
    components: {HeaderWidgetComponent},
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false
        },
        asset: {
            type: Object,
            required: true,
            default: function () {
                return {actual_volume: null, hollow: null, remaining_level: null};
            }
        }
    }
}
</script>