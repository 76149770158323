<template>
    <div id="chart-level-component" v-if="device.is_subscription_active">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component
                    :is_open="is_open"
                    widget_name="chart-level-component"
                    :title="$t('chart_level_component.evolution_level_liter')"
                    :actions="[
                       /* {
                            title: 'Editer le widget',
                            icon: 'mdi-pencil',
                            emit: 'editChart'
                        },
                        {
                            title: 'Télécharger',
                            icon: 'mdi-download',
                            emit: ''
                        }*/
                    ]"
                    v-on:editChart="openDialogEditChart()"
                    ref="headerWidgetComponent"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text class="pt-0 pb-4">
                        <date-range-picker-component :date_range="date_range" v-on:update="updateRangeDate"
                                                     :loading="loading"
                        />
                        <div class="text-center">
                            <highcharts v-if="chart_options.series[0].data !== null" :options="chart_options"/>
                        </div>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>

        <v-dialog v-model="dialog_add_custom_pin" max-width="500px">
            <v-form ref="AddCustomPin">
                <v-card>
                    <v-card-title>
                        <p class="text-uppercase font-weight-bold">{{ $t('chart_level_component.configure_event') }}</p>
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-text-field v-model="custom_pin_text" :rules="[rules.required]"
                                      filled label="Nom du marqueur"
                        />
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="closeDialogAddCustomPin()">
                            <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>
                        <v-btn color="secondary" text @click="addCustomPin()">
                            <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <dialog-edit-widget-chart-component ref="DialogEditWidgetChartComponent" :edit="true"
                                            :chart_configuration="chart_configuration"
                                            v-on:editChartConfiguration="editChartConfiguration"
        />
    </div>
</template>

<script>
import moment from "moment";
import DialogEditWidgetChartComponent from "@/components/DialogEditWidgetChartComponent";
import axios from "@/plugins/axios";
import DateRangePickerComponent from "../Global/DateRangePickerComponent";
import DeviceRepository from "../../repositories/DeviceRepository";
import HeaderWidgetComponent from "./HeaderWidgetComponent";

export default {
    name: 'ChartLevelComponent',
    components: {DateRangePickerComponent, DialogEditWidgetChartComponent, HeaderWidgetComponent},
    created() {
        if (this.device.is_subscription_active) {
            this.chart_options.tooltip.xDateFormat = this.date_format_chart;
            this.getChartData();
        }
    },
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false
        },
        device: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            menu_date_picker: false,
            dialog_add_custom_pin: false,
            selected_timestamp: '',
            max_value: null,

            custom_pin_text: null,
            loading: false,
            rules: {
                required: value => !!value || this.$t('global.field_required'),
                positive: value => parseInt(value) > 0 || this.$t('global.value_superior_zero'),
            },

            date_range: {
                startDate: this.moment().clone().startOf('day').subtract(2, 'month').toDate(),
                endDate: this.moment().toDate()
            },

            chart_configuration: {
                metric: {
                    id: 1,
                    libelle: this.$t('chart_level_component.volume')
                },
                unit: {
                    id: 2,
                    libelle: this.$t('chart_level_component.liter')
                },
                show_event_pins: true,
                show_alerts: true,
                show_custom_pins: true,
                show_navigator: true,
                name_widget: this.$t('chart_level_component.evolution_level_liter')
            },

            chart_data: [],
            alerts_pins: [],
            event_pins: [],
            custom_pins: [],
            plot_lines: [],

            chart_options: {
                lang: {
                    noData: this.$t('error_display.no_data')
                },
                time: {
                    timezone: 'Europe/Paris',
                },
                title: {
                    text: null
                },
                chart: {
                    type: 'area',
                    events: {
                        //this.chart
                        load: (function(self) {
                            return function() {
                                self.chart = this;
                            }
                        })(this)
                    }
                },
                legend: false,
                navigator: {
                    enabled: true,
                    maskFill: this.$vuetify.theme.themes.light.primary
                },
                series: [
                    {
                        name: this.$t('chart_level_component.evolution_quantity'),
                        data: null,
                        id: 'dataseries',
                        color: this.$vuetify.theme.themes.light.primary,
                        showInLegend: false,
                        fillOpacity: 0.2,
                        events: {
                            click: (event) => {
                                //this.openDialogAddCustomPin(event);
                            }
                        }
                    },
                    {
                        type: 'flags',
                        data: [],
                        onSeries: 'dataseries',
                        shape: 'circlepin',
                        width: 10,
                        color: 'red',
                    },
                    {
                        type: 'flags',
                        data: [],
                        onSeries: 'dataseries',
                        shape: 'circlepin',
                        width: 16,
                        color: this.$vuetify.theme.themes.light.primary,
                    },
                    {
                        type: 'flags',
                        data: [],
                        onSeries: 'dataseries',
                        shape: 'circlepin',
                        width: 16,
                        color: this.$vuetify.theme.themes.light.secondary,
                    },
                    //shape: 'url(/images/qsdsqd.png)',
                ],

                xAxis: {
                    type: 'datetime',
                    labels: {
                        formatter: function () {
                            return moment(this.value).format('DD MMM');
                        }
                    },
                    max: moment().valueOf()
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    min: 0,
                    labels: {
                        formatter: function () {
                            return this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' L';
                        }
                    },
                    plotLines: []
                },
                tooltip: {
                    xDateFormat: ''
                },
            },
        }
    },
    methods: {
        getChartData() {
            this.loading = true;
            this.chart_options.yAxis.min = 0;

            DeviceRepository.getDeviceDtdByIdDevice(
                this.device.id_device, `?date_start=${this.setDateForRequest(this.date_range.startDate)}&date_end=${this.setDateForRequest(this.date_range.endDate)}`
            ).then((success) => {
                this.chart_data = [];
                success.data.data.forEach((measure) => {
                    this.chart_data.push([this.moment(measure.date_releve).valueOf(), measure.volume]);
                });
                this.manageChartInformation();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading = false;
            });


            axios.get('https://8b6090ff-c5d2-4a92-a656-8fe7e45d0031.mock.pstmn.io/api/v1/chart').then((success) => {

                this.max_value = success.data.data.series_data.max_value;

                /*    this.chart_data = [];
                     success.data.data.series_data.main_serie.forEach(data => {
                         this.chart_data.push([this.moment(data[0]).valueOf(), data[1]]);
                     })
     */
                this.event_pins = [];
                success.data.data.series_data.event.pins.forEach(pin => {
                    this.event_pins.push({x: this.moment(pin.x).valueOf(), text: pin.text});
                })

                this.alerts_pins = [];
                if (success.data.data.series_data.alert) {
                    success.data.data.series_data.alert.pins.forEach(pin => {
                        this.alerts_pins.push({x: this.moment(pin.x).valueOf(), text: pin.text});
                    })

                    this.plot_lines = [
                        {
                            color: this.$vuetify.theme.themes.light.error, // Color value
                            width: 1, // Width of the line,$
                            value: this.max_value * (success.data.data.series_data.alert.level / 100),
                            label: {
                                text: '< ' + success.data.data.series_data.alert.level + '%',
                                style: {
                                    color: this.$vuetify.theme.themes.light.error,
                                    fontWeight: 'bold'
                                }
                            }
                        }
                    ];
                }

                this.custom_pins = [];
                success.data.data.series_data.custom.pins.forEach(pin => {
                    this.custom_pins.push({x: this.moment(pin.x).valueOf(), text: pin.text});
                })

                this.chart_configuration = success.data.data.chart_configuration;
            }).catch((error) => {
                this.manageError(error);
            })
        },

        updateRangeDate(date_range) {
            this.date_range = date_range;
            this.getChartData();
        },

        openDialogAddCustomPin(event) {
            if (this.chart_configuration.show_custom_pins) {
                this.dialog_add_custom_pin = true;
                this.selected_timestamp = event.point.options.x;
            }
        },

        addCustomPin() {
            this.custom_pins.push({x: this.selected_timestamp, text: this.custom_pin_text});
            this.closeDialogAddCustomPin();
        },

        closeDialogAddCustomPin() {
            this.dialog_add_custom_pin = false;
            this.selected_timestamp = '';
            this.$refs.AddCustomPin.reset();
        },

        manageChartInformation() {
            this.chart_options.series[0].data = this.chart_data;

            this.chart_options.series[1].data = [];
            this.chart_options.series[2].data = [];
            this.chart_options.series[3].data = [];
            this.chart_options.yAxis.plotLines = [];

            //Avoid error
            setTimeout(() => {
                this.chart.xAxis[0].setExtremes(this.moment(this.date_range.startDate).valueOf(), this.moment(this.date_range.endDate).valueOf());
            }, 200);

            /*  if (this.chart_configuration.show_alerts) {
                  this.chart_options.series[1].data = this.alerts_pins;
                  this.chart_options.yAxis.plotLines = this.plot_lines;
              }
              if (this.chart_configuration.show_event_pins) {
                  this.chart_options.series[2].data = this.event_pins;
              }
              if (this.chart_configuration.show_custom_pins) {
                  this.chart_options.series[3].data = this.custom_pins;
              }
  */

            this.chart_options.navigator.enabled = this.chart_configuration.show_navigator;
        },

        openDialogEditChart() {
            this.$refs.DialogEditWidgetChartComponent.openDialogEditChart();
        },

        editChartConfiguration(chart_configuration) {
            this.chart_configuration = JSON.parse(JSON.stringify(chart_configuration));
            this.manageChartInformation();
            this.$refs.HeaderWidgetComponent.bottom_sheet = false;
        }
    }
}
</script>

<style>
.highcharts-series-1 .highcharts-label text {
    display: none;
}

.highcharts-series-1 .highcharts-label path {
    fill: #ff0000;
}

.highcharts-series-2 .highcharts-label text {
    display: none;
}

.highcharts-series-2 .highcharts-label path {
    fill: var(--v-primary-base) !important;
}

.highcharts-series-3 .highcharts-label text {
    display: none;
}

.highcharts-series-3 .highcharts-label path {
    fill: var(--v-secondary-base) !important;
}

.highcharts-navigator-mask-inside {
    opacity: 0.10;
}

/*.highcharts-credits {
    display: none;
}*/

.highcharts-background {
    fill: var(--v-white-base) !important;
}

.highcharts-axis-labels text, .highcharts-yaxis-labels text {
    fill: var(--v-default_text-base) !important;;
}
</style>