<template>
    <div id="info-level-component">
        <span @click="openOrCloseWidgetEquipment(widget_name)" class="font-16 pointer add-space-after mr-14">
            {{ title }}
        </span>
        <div class="btn-open-setting-widget">
            <v-icon @click="openOrCloseWidgetEquipment(widget_name)">
                {{ is_open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>

            <v-menu
                offset-x
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" @click="bottom_sheet = true" data-cy="widget-option-btn">
                        mdi-dots-vertical
                    </v-icon>
                </template>

                <v-list v-if="!is_mobile">
                    <section v-for="action in actions" :key="action.title">
                        <v-list-item @click="$emit(action.emit)" v-if="userHasAccess(action.module, action.role)" :data-cy="action.data_cy">
                            <v-list-item-icon class="my-auto mr-6">
                                <v-icon>{{ action.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="my-0">
                                <span>{{ action.title }}</span>
                            </v-list-item-title>
                        </v-list-item>
                    </section>

                    <v-list-item @click="deleteWidgetEquipment(widget_name)" data-cy="delete-widget-btn">
                        <v-list-item-icon class="my-auto mr-6">
                            <v-icon color="error">mdi-trash-can</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="my-0">
                            <span class="error--text">{{ $t('header_widget_component.delete_widget') }}</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <v-bottom-sheet v-if="is_mobile" v-model="bottom_sheet">
            <v-sheet v-touch:swipe.bottom="swipeBottomHandler">
                <v-list>
                    <section v-for="action in actions" :key="action.title">
                        <v-list-item @click="$emit(action.emit)" v-if="userHasAccess(action.module, action.role)">
                            <v-list-item-icon class="my-auto mr-6">
                                <v-icon>{{ action.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="my-0">
                                <span>{{ action.title }}</span>
                            </v-list-item-title>
                        </v-list-item>
                    </section>

                    <v-list-item @click="deleteWidgetEquipment(widget_name)" data-cy="delete-widget-btn">
                        <v-list-item-icon class="my-auto mr-6">
                            <v-icon color="error">mdi-trash-can</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="my-0">
                            <span class="error--text">{{ $t('header_widget_component.delete_widget') }}</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
export default {
    name: "HeaderWidgetComponent",
    props: {
        title: {
            type: String,
            required: true
        },
        is_open: {
            type: Boolean,
            required: true
        },
        widget_name: {
            type: String,
            required: true
        },
        actions: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            bottom_sheet: false
        }
    },
    methods: {
        swipeBottomHandler() {
            this.bottom_sheet = false;
        }
    }
}
</script>

<style>
.btn-open-setting-widget {
    position: absolute !important;
    right: 15px !important;
    top: 10px !important;
}

.add-space-after:after {
    content: "\00a0";
}
</style>