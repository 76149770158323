<template>
    <div id="image-equipment-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component
                    :is_open="is_open" widget_name="image-equipment-component"
                    :title="$t('image_equipment_component.asset_image')"
                    :actions="[{
                        title: $t('image_equipment_component.import_image'),
                        icon: 'mdi-download',
                        emit: 'inputFile',
                        module: 'TNK',
                        role: role_all_access,
                        data_cy: 'btn-dialog-import-asset-image'
                     },
                     {
                        title:  $t('image_equipment_component.manage_image'),
                        icon: 'mdi-pencil',
                        emit: 'deleteImage',
                        module: 'TNK',
                        role: role_all_access,
                        data_cy: 'btn-dialog-manage-asset-image'
                     }]"
                     v-on:deleteImage="dialog_delete_image = true"
                     v-on:inputFile="$refs.inputFile.click()"
                />
            </v-card-title>

            <v-expand-transition>
                <v-card-text v-show="is_open">
                    <div v-if="asset.path_images.length  > 0" class="mx-2">
                        <v-carousel v-if="asset.path_images.length > 0" v-model="showing_image"
                                    :height="!is_mobile ? 300 : 150" hide-delimiters
                        >
                            <v-carousel-item v-for="(image, i) in asset.path_images" :key="i">
                                <v-img :src="image" contain :max-height="!is_mobile ? 300 : 150"/>
                            </v-carousel-item>
                        </v-carousel>
                    </div>

                    <div v-else class="text-center">
                        <p class="mb-1">{{ $t('image_equipment_component.no_image_available') }}</p>
                        <v-btn color="primary" outlined @click="$refs.inputFile.click()"
                               v-if="userHasAccess('TNK', role_all_access)"
                               data-cy="upload-asset-image"
                        >
                            <span class="text-uppercase primary--text">
                                {{ $t('image_equipment_component.download_image') }}
                            </span>
                        </v-btn>
                    </div>

                    <input ref="inputFile" class="input-upload-picture" type="file" @change="prepareUpload"
                           v-if="userHasAccess('TNK', role_all_access)" accept="image/png, image/jpeg"
                    >
                </v-card-text>
            </v-expand-transition>
        </v-card>

        <v-dialog v-model="dialog_delete_image" max-width="500px" scrollable data-cy="dialog-manage-image" v-if="userHasAccess('TNK', role_all_access)">
            <v-card>
                <v-card-title>
                    <span class="font-weight-bold">{{ $t('image_equipment_component.manage_images') }}</span>
                </v-card-title>

                <v-card-text>
                    <div class="dropzone-asset-picture mb-2" @dragenter="dragging = true"
                         @dragleave="dragging = false"
                    >
                        <div class="dropzone-info-asset-picture pointer" @drag="prepareUpload">
                            <div class="dropzone-upload-limit-info-asset-picture pa-3 text-center my-auto">
                                <v-icon>mdi-download</v-icon>
                                <span :class="dragging ? 'primary--text' : ' font-color-medium'">
                                    {{ $t('image_equipment_component.import_image') }}
                                </span>
                                <p class="font-color-light font-14 mb-0">
                                    {{ $t('image_equipment_component.drop_or_click_mage') }}
                                </p>
                                <input type="file" @change="prepareUpload" class="input-asset-picture"
                                       accept="image/png, image/jpeg"
                                >
                            </div>
                        </div>
                    </div>

                    <v-row class="ma-0 align-center" v-for="(image, index) in asset.path_images" :key="index" data-cy="row-upload-asset-image">
                        <v-col cols="1">
                            <v-checkbox v-model="selected_image" :value="index" data-cy="row-upload-asset-image-checkbox"/>
                        </v-col>
                        <v-col cols="11">
                            <v-img :src="image" contain max-height="150"/>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_delete_image = false; selected_image = []" data-cy="dialog-manage-image-close-btn">
                        <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn :loading="loading_edit" class="btn-error-loader" text @click="removePicture" data-cy="dialog-manage-image-delete-btn">
                        <span class="font-weight-bold error--text">{{ $t('global.delete') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AssetRepository from "../../repositories/AssetRepository";
import HeaderWidgetComponent from "./HeaderWidgetComponent";

export default {
    name: 'ImageEquipmentComponent',
    components: {HeaderWidgetComponent},
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false
        },
        asset: {
            type: Object,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            valid_format_picture: 'image/png, image/jpeg',
            showing_image: 0,
            loading_edit: false,
            dialog_delete_image: false,
            dragging: false,
            selected_image: []
        }
    },
    methods: {
        prepareUpload(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging = false;
                return;
            }

            if (this.checkFiles(files[0])) {
                this.uploadPicture(files);
            }
        },

        checkFiles(new_file) {
            let valid = true;
            if (!this.valid_format_picture.includes(new_file.type)) {
                this.showSnackbar('error', this.$t('image_equipment_component.file_type_not_authorized'));
                valid = false;
            }

            return valid;
        },

        uploadPicture(files) {
            let formData = new FormData();

            files.forEach((file) => {
                formData.append('files[]', file);
            });

            AssetRepository.postUploadPicture(this.asset.id_asset, formData).then(() => {
                this.showSnackbar('success', this.$t('image_equipment_component.image_imported'));
                this.$emit('refreshTank');
            }).catch((error) => {
                this.manageError(error);
            });
        },

        removePicture() {
            this.selected_image.forEach(index => {
                this.loading_edit = true;
                let name = '';
                let i = this.asset.path_images[index].length;
                while (i--) {
                    if (this.asset.path_images[index].charAt(i) === '/') {
                        break;
                    }
                    name += this.asset.path_images[index].charAt(i);
                }

                AssetRepository.deletePicture(this.asset.id_asset, [...name].reverse().join("")).then(() => {
                    this.showSnackbar('success', this.$t('image_equipment_component.delete_success'));
                    this.$emit('refreshTank');
                }).catch((error) => {
                    this.manageError(error);
                }).finally(() => {
                    this.loading_edit = false;
                    this.dialog_delete_image = false;
                });
            });
            this.selected_image = [];
        }
    }
}
</script>

<style>
.input-upload-picture {
    display: none;
}

.dropzone-info-asset-picture {
    border: 1px dashed #7f828a;
    border-radius: 5px 5px 0 0 !important;
    position: relative;
}

.dropzone-info-asset-picture:hover {
    border: 1px dashed var(--v-primary-base);
}

.input-asset-picture {
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>